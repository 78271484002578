import { defineMessages } from 'react-intl-next';

export const i18n = defineMessages({
	emptyMacro: {
		id: 'excerpt-include.empty',
		defaultMessage: 'No excerpts found.',
		description: 'Message to display when no excerpts are in the source page',
	},
	emptyMacroEdit: {
		id: 'excerpt-include.empty.edit',
		defaultMessage: 'Select{editIcon}to choose a different source page.',
		description:
			'Message to display when no excerpts are in the source page and the macro is in edit mode. {0} is the edit pencil icon.',
	},
	untitledExcerpt: {
		id: 'excerpt-include.untitled',
		defaultMessage: 'Untitled excerpt',
		description:
			'Excerpt title to display in Insert excerpt macro panel when an excerpt has no title',
	},
});
