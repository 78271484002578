import type { FC } from 'react';
import React, { useMemo, Fragment } from 'react';
import { useQuery } from 'react-apollo';
import { ApolloError } from 'apollo-client';

import {
	getMacroAttributesFromADFNode,
	MacroExperienceStop,
	getExperienceName,
} from '@confluence/macro-tracker';

import { ExcerptIncludeComponent } from './ExcerptIncludeComponent';
import { ExcerptIncludeComponentShadow } from './ExcerptIncludeComponentShadow';
import { ExcerptIncludeError } from './ExcerptIncludeError';
import { ExcerptIncludeQuery } from './ExcerptIncludeQuery.experimentalgraphql';
import type { ExcerptIncludeQuery as ExcerptIncludeQueryType } from './__types__/ExcerptIncludeQuery';
import type { ExcerptIncludeProps } from './types';
import { getMacroId, getMacroParam, parseMacroOutputAdf } from './utils';

/**
 * Component that is responsible for managing a state requred for
 * excerpt-include macro. It could either:
 * - Try to look for data in macroOutput param of the macro, if not present then:
 * - Query GraphQL enpoint and fetche the excerpt data from the target page.
 * The actual rendering is happening is delegated to ExcerptIncludeComponent.
 */
export const ExcerptIncludeImpl: FC<ExcerptIncludeProps> = ({
	contentId,
	spaceKey,
	extensionKey,
	macroNode,
	mode,
	RendererWrapper,
	isLegacyRenderer,
	featureFlags,
	isLivePage,
}) => {
	const {
		extensionType,
		parameters: { macroMetadata, macroParams, macroOutput, mediaToken },
		type,
	} = macroNode;

	const attributes = getMacroAttributesFromADFNode(macroNode);

	const adfFromMacroOutput = useMemo(
		() => parseMacroOutputAdf(macroOutput)?.content,
		[macroOutput],
	);

	const { data, loading, error } = useQuery<ExcerptIncludeQueryType>(ExcerptIncludeQuery, {
		variables: {
			contentId,
			macroNode: {
				extensionKey,
				extensionType,
				parameters: {
					macroMetadata: JSON.stringify(macroMetadata),
					macroParams: JSON.stringify(macroParams),
				},
				type,
			},
		},
		// Avoid to do a query if content already provided in `macroOutput` param
		skip: !!adfFromMacroOutput,
	});

	const targetContentTitle = getMacroParam(macroNode, '') || '';
	const shouldPanelBeDisplayed = getMacroParam(macroNode, 'nopanel') !== 'true';
	const displayInline = getMacroParam(macroNode, 'inline') === 'true';
	const excerptName = getMacroParam(macroNode, 'name');
	const macroId = getMacroId(macroNode);
	const targetContentId =
		data?.experimentalMacroMetadata?.contentId ||
		getMacroParam(macroNode, '_parentId') ||
		contentId ||
		'';

	const props = {
		RendererWrapper,
		extensionKey,
		macroNode,
		mode,
		pageId: contentId,
		spaceKey,
		shouldPanelBeDisplayed,
		displayInline,
		excerptName,
		targetContentTitle,
		macroId,
		isLegacyRenderer,
		featureFlags,
		isLivePage,
	};

	if (adfFromMacroOutput) {
		return (
			<ExcerptIncludeComponent
				{...props}
				adf={adfFromMacroOutput}
				contentId={targetContentId}
				mediaToken={mediaToken}
			/>
		);
	}

	// This point will be reached if macroOutput param does not contain target excerpt data.
	if (loading) {
		return (
			<ExcerptIncludeComponentShadow
				shouldPanelBeDisplayed={shouldPanelBeDisplayed}
				targetContentTitle={targetContentTitle}
			/>
		);
	}

	const adfString = data?.experimentalMacroMetadata?.payload;
	if (error) {
		const queryError =
			error ||
			new ApolloError({
				errorMessage: `${extensionKey} failed to render because macro metadata payload is empty`,
			});
		return (
			<Fragment>
				<MacroExperienceStop
					error={queryError}
					mode={mode}
					name={getExperienceName(mode, macroNode)}
					attributes={attributes}
					contentId={contentId}
					source="ExcerptIncludeImpl"
				/>
				<ExcerptIncludeError targetContentTitle={targetContentTitle} />
			</Fragment>
		);
	}

	return (
		<ExcerptIncludeComponent
			{...props}
			adf={adfString ? JSON.parse(adfString) : undefined}
			contentId={targetContentId}
		/>
	);
};
