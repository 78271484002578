import React, { PureComponent } from 'react';
import { injectIntl } from 'react-intl-next';
import type { WrappedComponentProps } from 'react-intl-next';

import { token } from '@atlaskit/tokens';
import EditorWarningIcon from '@atlaskit/icon/glyph/editor/warning';

type ExcerptIncludeErrorComponentProps = {
	targetContentTitle: string;
};

class ExcerptIncludeErrorComponent extends PureComponent<
	ExcerptIncludeErrorComponentProps & WrappedComponentProps
> {
	render() {
		const { targetContentTitle, intl } = this.props;

		return (
			<span
				style={{
					// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
					display: 'inline-block',
					// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
					backgroundColor: token('elevation.surface', '#F4F5F7'),
					// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
					borderRadius: 5,
					// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
					width: 668,
					// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
					height: 25,
					// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
					padding: token('space.050', '4px'),
				}}
			>
				<EditorWarningIcon size="medium" label="" />
				<span
					style={{
						// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
						paddingLeft: token('space.050', '4px'),
						// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
						verticalAlign: 'text-bottom',
					}}
				>
					{intl.formatMessage(
						{
							id: 'editor.excerpt.include.macro.error',
						},
						{
							0: targetContentTitle,
						},
					)}
				</span>
			</span>
		);
	}
}

export const ExcerptIncludeError = injectIntl(ExcerptIncludeErrorComponent);
