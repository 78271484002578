import type { FC } from 'react';
import React, { useEffect, useState } from 'react';

import type { ExcerptIncludeProps } from './types';
import {
	RecursiveExcerptContextProvider,
	useRecursiveExcerptContext,
} from './RecursiveExcerptContext';
import { RecursiveExcerptError } from './RecursiveExcerptError';
import { ExcerptIncludeImpl } from './ExcerptIncludeImpl';

export const ExcerptInclude: FC<ExcerptIncludeProps> = (props) => {
	const { isProviderAvailable, addKey, hasKey, removeKey } = useRecursiveExcerptContext();
	const [circularReferenceDetected, setCircularReferenceDetected] = useState<boolean | undefined>(
		undefined,
	);

	const excerptPageTitle = props?.macroNode?.parameters?.macroParams?.['']?.value;
	const excerptName =
		props?.macroNode?.parameters?.macroParams?.name?.value ?? 'UNDEFINED_EXCERPT_NAME';
	const excerptKeyFromMacroParams =
		excerptPageTitle !== undefined ? `${excerptPageTitle}-${excerptName}` : undefined;

	useEffect(() => {
		if (!excerptKeyFromMacroParams || !isProviderAvailable) return;
		let didAdd = false;

		if (hasKey(excerptKeyFromMacroParams)) {
			// If the key already exists, we must be in a loop.
			// The key shouldn't change for an already-rendered excerpt, so
			// this effect shouldn't fire again except for new excerpts.
			setCircularReferenceDetected(true);
		} else {
			// The first time we see the key, add it.
			setCircularReferenceDetected(false);
			addKey(excerptKeyFromMacroParams);
			didAdd = true;
		}

		return () => {
			if (didAdd) {
				removeKey(excerptKeyFromMacroParams);
			}
		};
	}, [excerptKeyFromMacroParams, isProviderAvailable, hasKey, addKey, removeKey]);

	if (!isProviderAvailable) {
		// The top-most excerpt-include will provide the first excerpt key.
		// Child excerpt-includes will add their keys via the `addKey` function.
		return (
			<RecursiveExcerptContextProvider excerptKey={excerptKeyFromMacroParams}>
				<ExcerptIncludeImpl {...props} />
			</RecursiveExcerptContextProvider>
		);
	} else if (circularReferenceDetected === undefined) {
		return null;
	} else if (circularReferenceDetected) {
		return <RecursiveExcerptError />;
	} else {
		return <ExcerptIncludeImpl {...props} />;
	}
};
