import gql from 'graphql-tag';

export const ExcerptIncludeQuery = gql`
	query ExcerptIncludeQuery($contentId: ID!, $macroNode: ExperimentalMacroNode!) @experimental {
		experimentalMacroMetadata(contentId: $contentId, macroNode: $macroNode) {
			payload
			contentId
		}
	}
`;
