import React, { createContext, useCallback, useContext, useEffect, useMemo } from 'react';

type RecursiveExcerptContextType = {
	hasKey: (key: string) => boolean;
	addKey: (key: string) => void;
	removeKey: (key: string) => void;
	isProviderAvailable: boolean;
};
const RecursiveExcerptContext = createContext<RecursiveExcerptContextType>({
	hasKey: () => false,
	addKey: () => {},
	removeKey: () => {},
	isProviderAvailable: false,
});

type RecursiveExcerptProviderProps = {
	excerptKey: string | undefined;
	children: React.ReactNode;
};
export const RecursiveExcerptContextProvider: React.FC<RecursiveExcerptProviderProps> = ({
	children,
	excerptKey,
}) => {
	const keys = useMemo(() => {
		return new Set();
	}, []);

	useEffect(() => {
		if (excerptKey) {
			keys.add(excerptKey);
		}
	}, [keys, excerptKey]);

	const hasKey = useCallback(
		(key: string) => {
			return keys.has(key);
		},
		[keys],
	);

	const addKey = useCallback(
		(key: string) => {
			keys.add(key);
		},
		[keys],
	);

	const removeKey = useCallback(
		(key: string) => {
			keys.delete(key);
		},
		[keys],
	);

	const contextValue = useMemo(() => {
		return { hasKey, addKey, removeKey, isProviderAvailable: true };
	}, [hasKey, addKey, removeKey]);

	return (
		<RecursiveExcerptContext.Provider value={contextValue}>
			{children}
		</RecursiveExcerptContext.Provider>
	);
};

export const useRecursiveExcerptContext = () => useContext(RecursiveExcerptContext);
