import type { ADDoc } from '@atlaskit/editor-common/validator';

export const parseMacroOutputAdf = (macroOutput: string) => {
	try {
		if (macroOutput) {
			const parsed = JSON.parse(macroOutput);
			if (parsed.version === 1 && parsed.type === 'doc' && parsed.content) {
				return parsed as ADDoc;
			}
		}
	} catch (e) {
		// do nothing in case of parsing error, which would return `undefined`
	}
};

export const getParameters = (node: any) => node?.parameters;
export const getMacroId = (node: any) => node?.parameters?.macroMetadata?.macroId?.value;
export const getMacroParam = (node: any, name: string) =>
	node?.parameters?.macroParams?.[name]?.value;
export const getExtensionKey = (node: any) => node?.attrs?.extensionKey;
